<template>
    <div>
        <CCard class="p-0">
            <CCardHeader>
                <div class="d-flex">
                    <div class="flex-grow-1">
                    </div>
                    <div class="flex-grow-0 ml-10">
                        <CButton class="btn-sm text-white" color="danger" @click="showPopupModal">
                            Tambah Baru
                        </CButton>
                    </div>
                </div>
            </CCardHeader>
            <CCardBody>
                <v-data-table
                    :loading="loading"
                    loading-text="Memuat Data..."
                    :headers="modelTable.headers"
                    :options.sync="optionsTable"
                    :server-items-length="modelTable.totalData"
                    :items="modelTable.dataList"
                    :single-expand="true"
                    :expanded.sync="modelTable.expandedTable"
                    item-key="workflowConfig"
                    show-expand
                    :footer-props="modelTable.footerProps"
                    class="elevation-1">
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon v-c-tooltip="'Ubah konfigurasi'" small class="mr-2 text-warning" @click="showEditPopupModal(item)">
                                mdi-pencil
                            </v-icon>
							<v-icon v-c-tooltip="'Jadikan konfigurasi bisa digunakan user lain'" small class="mr-2 text-success" v-if="item.status == 'Private'" @click="makeItPublish(item)">
								mdi-earth-arrow-right
							</v-icon>
							<v-icon v-c-tooltip="'Jadikan konfigurasi ini hanya bisa digunakan user sekarang'" small class="mr-2 text-success" v-else @click="makeItPrivate(item)">
								mdi-shield-account
							</v-icon>
                            <v-icon v-c-tooltip="'Hapus konfigurasi'" small class="text-danger" @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" class="p-4">
                                <div class="mb-20">
                                    Susunan Konfigurasi Alur: 
                                </div>
                                <div class="mb-20">
                                    <div class="d-flex mb-10">
                                        <div v-for="(obj, index) in item.flowDetail" :key="index" class="mr-20" style="width:190px">
                                            <div class="d-flex">
                                                <div class="flex-grow-1 mr-20">
                                                    <div class="content-center mb-10">
                                                        <div class="text-center">
                                                            <span class="badge badge-info p-1">{{obj.number}}</span>
                                                        </div>
                                                        <div class="media-img-wrap">
                                                            <div v-if="obj.profile_picture_thumb_base64" class="avatar avatar-sm content-center">
                                                                <img :src="obj.photo" alt="user" class="avatar-img rounded-circle img-fluid">
                                                            </div>
                                                            <div v-else class="avatar avatar-sm">
                                                                <img :src="avatar" alt="user" class="avatar-img rounded-circle img-fluid">
                                                            </div>                                                
                                                        </div>
                                                    </div>
                                                    <div class="text-center">
														<b> {{obj.position ? obj.position : 'Non Struktural'}} </b>
														<p> {{obj.role}} </p>
													</div>
                                                </div>
                                                <div class="flex-grow-0 p-2" style="margin-top:2rem">
                                                    <font-awesome-icon icon="chevron-right" class="content-center"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mr-20" style="width:190px">
                                            <div class="text-center" style="margin-top:2rem">
                                                <b> Disetujui </b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </template>
                        <template v-slot:>
                            <span>Data Tidak Ditemukan</span>
                        </template>
                </v-data-table>
            </CCardBody>
        </CCard>
        <!-- Popup Modal & Input Form -->
        <!-- Create -->
        <CModal
			:closeOnBackdrop="false"
			:show.sync="popupModal.isShow">
            <template #header>
                <h5 class="modal-title">{{ popupModal.modalTitle }}</h5>
				<CButtonClose @click="closePopupModal" class="text-gray"/>
            </template>
            <!-- Input Form -->
            <div class="p-1">
                <CInput
                    v-model="modelForm.input.workflowConfig"
                    label="Nama Konfigurasi"
                    placeholder="Nama Konfigurasi"
                    :is-valid="modelForm.validate.workflowConfig"
                    @input="validateForm('workflowConfig')" />
                <div v-for="(item, index) in modelForm.flowOption" :key="index" class="mb-10">
                    <label class="typo__label">Konfigurasi #{{index + 1}}</label>

					<CRow>
						<CCol lg="7" v-if="!isVerificationFlowProp" >
							<multiselect 
								v-model="modelForm.input.selectedUserConfig[index]"
								:options="item"
								:multiple="false"
								:allow-empty="true"
								:close-on-select="true"
								label="label"
								track-by="label"
								deselectLabel="Tekan 'enter' untuk menghapus"
								selectLabel="Tekan 'enter' untuk memilih"
								selectedLabel="Terpilih"
								@input="selectedConfigChange($event,index,modelForm)"
								@remove="removeSelectedConfig($event,index,modelForm)"
								placeholder="Pilih Pengguna">
							</multiselect>
						</CCol>
						<CCol lg="12" v-if="isVerificationFlowProp" >
							<multiselect 
								v-model="modelForm.input.selectedUserConfig[index]"
								:options="item"
								:multiple="false"
								:allow-empty="true"
								:close-on-select="true"
								label="label"
								track-by="label"
								deselectLabel="Tekan 'enter' untuk menghapus"
								selectLabel="Tekan 'enter' untuk memilih"
								selectedLabel="Terpilih"
								@input="selectedConfigChange($event,index,modelForm)"
								@remove="removeSelectedConfig($event,index,modelForm)"
								placeholder="Pilih Pengguna">
							</multiselect>
						</CCol>
						<CCol lg="5" v-if="!isVerificationFlowProp">
							<multiselect 
								v-model="modelForm.input.selectedUserRoleConfig[index]"
								:options="roleConfigOptions"
								:multiple="false"
								:allow-empty="false"
								label="roleValue"
								track-by="roleValue"
								deselectLabel=""
								selectLabel="Pilih"
								selectedLabel=""
								placeholder="Pilih Peran Tugas">
							</multiselect>
						</CCol>
					</CRow>
                </div>
            </div>
            <template #footer>
                <CButton @click="closePopupModal" class="btn-sm">Batal</CButton>
                <CButton @click="saveItem" color="success" class="btn-sm" :disabled="modelForm.submitBtn.disabled">
                    <font-awesome-icon v-if="modelForm.submitBtn.loader" icon="spinner" spin/> {{ modelForm.submitBtn.text }}
                </CButton>
            </template>
        </CModal>
        <!-- Edit -->
        <CModal
			:closeOnBackdrop="false"
			:show.sync="editModal.isShow"
		>
            <template #header>
                <h5 class="modal-title">{{ editModal.modalTitle }}</h5>
				<CButtonClose @click="closeEditPopupModal" class="text-gray"/>
            </template>
            <!-- Input Form -->
            <div class="p-1">
                <CInput
                    v-model="modelEditForm.input.workflowConfig"
                    label="Nama Konfigurasi"
                    placeholder="Nama Konfigurasi"
                    :is-valid="modelEditForm.validate.workflowConfig"
                    @change="validateEditForm('workflowConfig')" />
                <div v-for="(item, index) in modelEditForm.flowOption" :key="index" class="mb-10">
					<label class="typo__label">Konfigurasi #{{index + 1}}</label>
					<CRow>
						<CCol lg="7" v-if="!isVerificationFlowProp">
							<multiselect 
								v-model="modelEditForm.input.selectedUserConfig[index]"
								:options="item"
								:multiple="false"
								:allow-empty="true"
								:close-on-select="true"
								label="label"
								track-by="label"
								@input="selectedConfigChange($event,index,modelEditForm)"
								@remove="removeSelectedConfig($event,index,modelEditForm)"
								placeholder="Pilih Pengguna">
							</multiselect>
						</CCol>
						<CCol lg="12" v-if="isVerificationFlowProp">
							<multiselect 
								v-model="modelEditForm.input.selectedUserConfig[index]"
								:options="item"
								:multiple="false"
								:allow-empty="true"
								:close-on-select="true"
								label="label"
								track-by="label"
								@input="selectedConfigChange($event,index,modelEditForm)"
								@remove="removeSelectedConfig($event,index,modelEditForm)"
								placeholder="Pilih Pengguna">
							</multiselect>
						</CCol>
						<CCol lg="5" v-if="!isVerificationFlowProp">
							<multiselect 
								v-model="modelEditForm.input.selectedUserRoleConfig[index]"
								:options="roleConfigOptions"
								:multiple="false"
								:allow-empty="false"
								label="roleValue"
								track-by="roleValue"
								deselectLabel=""
								selectLabel="Pilih"
								selectedLabel=""
								placeholder="Pilih Peran Tugas">
							</multiselect>
						</CCol>
					</CRow>
				</div>
            </div>
            <template #footer>
                <CButton @click="closeEditPopupModal" class="btn-sm">Batal</CButton>
                <CButton @click="updateItem" color="success" class="btn-sm" :disabled="modelEditForm.submitBtn.disabled">
                    <font-awesome-icon v-if="modelEditForm.submitBtn.loader" icon="spinner" spin/> {{ modelEditForm.submitBtn.text }}
                </CButton>
            </template>
        </CModal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import 'vue-multiselect/dist/vue-multiselect.min.css';
const avatar = require('@/assets/img/avatar.svg')
export default {
    props: {
        isDispositionProp: {
            type: Boolean,
            default: undefined
        },
		isVerificationFlowProp: {
			type: Boolean,
			default: false
		}
    },
    data () {
		const modelTable = {
			headers: [
				{ text: '', value: 'data-table-expand', sortable: false, align: 'center' },
				{ text: 'Alur Kerja', value: 'workflowConfig', align: 'center', sortable: true },
				{ text: 'Konfigurasi', value: 'flow', align: 'center', sortable: false },
				{ text: 'Status', value: 'status', align: 'center', sortable: true},
				{ text: 'Aksi', value: 'actions', align: 'center', sortable: false }
			],
			footerProps: {
				'disable-items-per-page': true,
				'items-per-page-text': 'Data Ditampilkan'
			},
			totalData: 0,
			dataList: [],
			expandedTable: []
		}
		const popupModal = {
			isShow: false,
			modalTitle: 'Tambah Data Alur Kerja'
		}
		const modelForm = {
            editedIndex: -1,
            flowOption: [],
			input: {
                isDisposition: this.isDispositionProp,
                workflowConfig: null,
				positionId: null,
				positionIdDraft: null,
				selectedUserConfig: [],
				selectedUserRoleConfig: []
			},
			defaultValue: {
                isDisposition: this.isDispositionProp,
                workflowConfig: null,
				positionId: null,
				positionIdDraft: null,
				selectedUserRoleConfig: []
			},
			validate: {
                workflowConfig: null,
				flow: null
			},
			submitBtn: {
				loader: false,
                text: 'Simpan',
				disabled: true
			}
		}
		const editModal = {
			isShow: false,
			modalTitle: 'Ubah Alur Kerja'
		}
		const modelEditForm = {
			editedIndex: -1,
            flowOption: [],
			input: {
                workflowId: null,
                workflowConfig: null,
				positionId: null,
				positionIdDraft: null,
				selectedUserConfig: [],
				selectedUserRoleConfig: []
			},
			defaultValue: {
                workflowId: null,
                workflowConfig: null,
				positionId: null,
				positionIdDraft: null,
				selectedUserConfig: [],
				selectedUserRoleConfig: []
			},
			validate: {
                workflowId: null,
                workflowConfig: null,
				flow: null
			},
			submitBtn: {
				loader: false,
                text: 'Simpan',
				disabled: true
			}
		}
		const roleConfigOptions = [
			{
				roleId: 1,
				roleValue : "Approval TTD"
			},
			{
				roleId: 2,
				roleValue: "Approval Non TTD"
			},
			{
				roleId: 3,
				roleValue: "Penomoran"
			}
		]


		return {
			loading: true,
			avatar,
			modelTable,
			optionsTable: {},
			popupModal,
			modelForm,
			editModal,
			modelEditForm,
			roleConfigOptions
		}
	},
	computed: {
        ...mapState('auth', {
			authUserId: 'user_id',
			authPositionId: 'position_id',
			authPosition: 'position',
			authDivisionId: 'satker_id',
			authDivision: 'satker_name'
        }),
		configSelected() {
			return this.modelForm.input.selectedUserConfig
		},
		configEditSelected() {
			return this.modelEditForm.input.selectedUserConfig
		}
	},
	watch: {
		optionsTable: {
			deep: true,
			handler () {
				this.getDataList()
					.then( data => {
						this.modelTable.dataList = data.items
						this.modelTable.totalData = data.total
						this.loading = false
					})
			}
		},
		configSelected () {
			this.validateForm('flow')
		},
		configEditSelected () {
			this.validateEditForm('flow')
		}
    },
	mounted () {
		this.getDataList()
			.then( data => {
				this.modelTable.dataList = data.items
				this.modelTable.totalData = data.total
				this.loading = false
            })
	},
	methods: {
		// Fetch Data
		getDataList () {
			this.loading = true
			return new Promise((resolve, reject) => {
				const { sortBy, sortDesc, page, itemsPerPage } = this.optionsTable

				this.$store.dispatch({
                    type: 'workflow/get',
                    pagination: {
                        limit: itemsPerPage,
                        offset: (page-1)*itemsPerPage
                    },
                    order: {
                        orderBy: (sortBy.length > 0) ? sortBy[0] : 'config_workflow',
                        orderType: (sortDesc.length > 0 && sortDesc[0])? 'desc' : 'asc'
                    },
                    filter: {
                        isDisposition: this.isDispositionProp
                    }
                }).then( res => {
					let items = []
					let total = 0
					res.result.forEach((item) => {
						item.flow.forEach((obj) => {
							obj.photo = "data:image/jpeg;base64," + obj.profile_picture_thumb_base64
						})
						let rowData = {
							workflowId: item.workflow_id,
                            workflowConfig: item.workflow_config,
                            flow: item.flow.length+" tahap pemeriksaan",
                            flowDetail: item.flow,
							status: item.status
						}
						items.push(rowData)
					})
					total = res.total_count
					
					setTimeout(() => {
						resolve({
							items,
							total
						})
					}, 1000)
				})
			})
		},
        getUserConfigFlowOption (criteria = null) {
			return new Promise((resolve, reject) => {
				let filter = {}
				if (this.authUserId && this.authDivisionId != undefined)
					filter = { satkerId: this.authDivisionId }

				this.$store.dispatch({
					type: 'position/getUserConfig',
					pagination: {
						limit: 1000,
						offset: 0
					},
					order: {
						orderBy: 'position',
						orderType: 'asc'
					},
					filter: filter
				}).then( res => {
					let items = []
					if (res) {
						let selectedPositions = []
						if (criteria == null) {
							// Retrieve positionId from selected positions workflow config
							var selectedPositionConfigs = this.modelForm.input.selectedUserConfig
							if(selectedPositionConfigs == undefined) {
								selectedPositionConfigs = []
								this.modelForm.input.selectedUserConfig = []
							}
							for (let i = 0; i < selectedPositionConfigs.length; i++) {
								const element = selectedPositionConfigs[i];
								selectedPositions.push(element)
							}
						}

						// Filtering fetched data result into criteria or exclude position from selected position list
						for (let i = 0; i < res.result.length; i++) {
							const item = res.result[i];
							let rowData = {
								label: item.username.trim() +' - '+ item.position.trim(),
								userId: item.user_id,
								positionId: item.position_id,
								position: item.position,
								configPosition: item.config_position
							}
							
							const positionExisted = (selectedPositions.length > 0)
								? selectedPositions.some(el => el.positionId === item.position_id)
								: false
							// if (criteria == null && positionExisted === false) {
							// 	items.push(rowData)
							// } else if (criteria != null && item.position_id === criteria.positionPid) {
							// 	items.push(rowData)
							// }
							items.push(rowData)
						}
					}
					resolve({items})
				}).catch( err => {
					reject(err)
				})
			})
		},
		getPositionDetail (positionId) {
			return new Promise( (resolve, reject) => {
				this.$store.dispatch(
					{
						type: 'position/fetch',
						positionId: positionId
					}
				).then( res => {
					if (res) {
						resolve(res.result[0])
					} else {
						reject(res)
					}
				}).catch( err => {
                    reject(err)
                })
			})
		},

		// Formating Data
		async loadConfigList (item, idx) {
			if (item) {
				let configVal = {
					index: idx,
					positionId: item.positionId,
					positionPid: null,
					userId: item.userId,
				}
				let dataRow = await this.getPositionDetail(item.positionId)
				if (dataRow) {
					configVal['positionPid'] = dataRow.position_pid
				}

				this.modelEditForm.input.selectedUserConfig[idx] = item
				if (dataRow.position_pid != null) {
					this.getUserConfigFlowOption(configVal)
						.then( data => {
							let lastIndex = this.modelEditForm.input.selectedUserConfig.length-1
							let filteredItems = data.items
							if (idx > 0 && lastIndex != 0) {
								filteredItems = data.items.filter( option => {
									return (option.positionId != this.modelEditForm.input.selectedUserConfig[lastIndex].positionId && option.positionId != this.modelEditForm.input.selectedUserConfig[0].positionId)
								})
							}

							this.modelEditForm.flowOption.push(filteredItems)
						})
				}
			}
		},
		async selectedConfigChange (item, idx, targetModel) {
			if (item) {
				let configVal = {
					index: idx,
					positionId: item.positionId,
					positionPid: null,
					userId: item.userId,
				}
				const dataRow = await this.getPositionDetail(item.positionId)
				if (dataRow)
					configVal['positionPid'] = dataRow.position_pid
	
				let startIdx = idx + 1
				let lengthData = targetModel.flowOption.length - startIdx
				targetModel.input.selectedUserConfig.splice(startIdx, lengthData)
				targetModel.flowOption.splice(startIdx, lengthData)

				// If workflow state is verification workflow, then disabled fetch parent position for next position flow options
				// if (this.isDispositionProp === true)
					configVal = null
	
				this.getUserConfigFlowOption(configVal)
					.then( data => {
						let filteredItems = data.items
						if (!targetModel.flowOption[startIdx]) {
							targetModel.flowOption.push(filteredItems)
						} else {
							targetModel.flowOption[startIdx] = filteredItems
						}
					})
			}
		},

		removeSelectedConfig (item,idx,targetModel) {
			let startIdx = idx
			let lengthData = targetModel.input.selectedUserConfig.length

			targetModel.input.selectedUserConfig.splice(startIdx,(lengthData-startIdx))
			targetModel.flowOption.splice(startIdx+1,(lengthData-(startIdx+1)))
		},
		formatFlow (selectedUserConfig) {
			let positionList = []
			let positionObj = {}


			if(this.isVerificationFlowProp) {
				for (let i=0;i<selectedUserConfig.length;i++) {
					if (selectedUserConfig[i]) {
						if (!positionList.includes(selectedUserConfig[i].positionId)) {
							positionList.push(selectedUserConfig[i].positionId)
							positionObj[selectedUserConfig[i].positionId] = [selectedUserConfig[i].positionId+'_'+selectedUserConfig[i].userId]
						} else {
							positionObj[selectedUserConfig[i].positionId].push(selectedUserConfig[i].positionId+'_'+selectedUserConfig[i].userId)
						}
					}
				}
			} else {
				var roleListData = []
				if(this.editModal.isShow == true) {
					roleListData = this.modelEditForm.input.selectedUserRoleConfig
				} else {
					roleListData = this.modelForm.input.selectedUserRoleConfig
				}
				
				for (let i=0;i<selectedUserConfig.length;i++) {
					if (selectedUserConfig[i]) {
						if (!positionList.includes(selectedUserConfig[i].positionId)) {
							positionList.push(selectedUserConfig[i].positionId)
							positionObj[selectedUserConfig[i].positionId] = [selectedUserConfig[i].positionId+'_'+selectedUserConfig[i].userId+'_'+roleListData[i].roleId]
						} else {
							positionObj[selectedUserConfig[i].positionId].push(selectedUserConfig[i].positionId+'_'+selectedUserConfig[i].userId+'_'+roleListData[i].roleId)
						}
					}
				}
			}

			let result = []
			positionList.forEach( item => {
				let objectInput = positionObj[item].join('|')
				result.push(objectInput)
			})

			return result
		},

		// Add Data
		showPopupModal () {
            this.popupModal.modalTitle = 'Tambah Data Alur Kerja'
            this.modelForm.editedIndex = -1
			this.modelForm.input.selectedUserRoleConfig = []
			
			this.modelForm.submitBtn.disabled = false

			this.getUserConfigFlowOption()
				.then( data => {
					this.modelForm.flowOption.push(data.items)
				}).finally(()=> 
			this.popupModal.isShow = true)
		},
		closePopupModal () {
			this.popupModal.isShow = false
			this.modelForm.flowOption = []
			this.modelForm.input = Object.assign({}, this.modelForm.defaultValue)
			this.modelForm.validate = {
                workflowConfig: null,
                flow: null
			}
			this.modelForm.submitBtn.disabled = true
		},
		validateForm (field) {
			if (field == 'workflowConfig') {
				if (this.modelForm.input.workflowConfig != '') {
					this.modelForm.validate.workflowConfig = true
				} else {
					this.modelForm.validate.workflowConfig = false
				}
            }

            if (field == 'flow') {
                if (this.modelForm.input.selectedUserConfig.length != 0 && this.modelForm.input.selectedUserConfig[0] != null) {
                    this.modelForm.validate.flow = true
                } else {
                    this.modelForm.validate.flow = false
                }
            }
            
            if (this.modelForm.validate.workflowConfig &&
                this.modelForm.validate.flow)
				this.modelForm.submitBtn.disabled = false
		},
		isNumberingRoleMoreThanOne() {
			var numberingDataList = this.modelForm.input.selectedUserRoleConfig.filter(data=> data.roleId == 3)
			if(numberingDataList.length > 1) {
				return true
			}
			return false
		},
		saveItem () {
			if(this.isNumberingRoleMoreThanOne()) {
				this.$toasted.error('Peran tugas Penomoran hanya boleh satu pengguna!').goAway(3000)
			} else {
				let selectedUserConfig = this.modelForm.input.selectedUserConfig
				this.modelForm.input.positionId = this.formatFlow(selectedUserConfig)

				let positionDraft = (selectedUserConfig.length > 0)?selectedUserConfig[selectedUserConfig.length-1]:null
				this.modelForm.input.positionIdDraft = positionDraft.positionId

				this.modelForm.submitBtn = {
					loader: true,
					text: 'Memproses...'
				}
				
				this.$store.dispatch({
					type: 'workflow/store',
					formInput: this.modelForm.input
				}).then( res => {
					this.getDataList()
						.then( data => {
							this.modelTable.dataList = data.items
							this.modelTable.totalData = data.total
							
							this.$toasted.success('Sukses menambah data').goAway(3000)
							this.modelForm.submitBtn = {
								loader: false,
								text: 'Simpan'
							}
							this.loading = false
							setTimeout( e => {
								this.closePopupModal()
							}, 500)
						})
				}).catch( err => {
					console.log(err)
					this.modelForm.submitBtn = {
						loader: false,
						text: 'Simpan'
					}
					this.$toasted.error('Gagal menambah data, silahkan coba kembali!').goAway(3000)
				})
			}
			
		},

		// Update Data
		showEditPopupModal (selectedItem) {
            this.modelEditForm.editedIndex = selectedItem.workflowId
            this.modelEditForm.input.workflowId = selectedItem.workflowId
            this.modelEditForm.input.workflowConfig = selectedItem.workflowConfig
			this.modelEditForm.submitBtn.disabled = false

			this.modelEditForm.flowOption = []
			this.modelEditForm.input.selectedUserConfig = []
			this.modelEditForm.input.selectedUserRoleConfig = []
			selectedItem.flowDetail.forEach(flowData=> {
				var roleConfig = {
					roleId: flowData.role_id,
					roleValue: flowData.role
				}
				
				this.modelEditForm.input.selectedUserRoleConfig.push(roleConfig)
			})


			this.getUserConfigFlowOption()
				.then( data => {
					this.modelEditForm.flowOption.push(data.items)
					
					selectedItem.flowDetail.forEach( (item,index) => {
						let configValue = this.modelEditForm.flowOption[0].find( option => option.userId === selectedItem.flowDetail[index].user_id)
						this.loadConfigList(configValue,index)
					})

					this.validateEditForm('workflowId')
					this.validateEditForm('workflowConfig')
					setTimeout( e => {
						this.validateEditForm('flow')
					}, 2000)
				}).finally(()=>
				
				this.editModal.isShow = true
			)
		},
		closeEditPopupModal () {
            this.modelEditForm.editedIndex = -1
			this.modelEditForm.input = Object.assign({}, this.modelEditForm.defaultValue)
			this.modelEditForm.validate = {
                workflowId: null,
                workflowConfig: null,
                flow: null
			}
			this.modelEditForm.submitBtn.disabled = true
			this.editModal.isShow = false
		},
		validateEditForm (field) {
			if (field == 'workflowId') {
				if (this.modelEditForm.input.workflowId != '') {
					this.modelEditForm.validate.workflowId = true
				} else {
					this.modelEditForm.validate.workflowId = false
				}
			}
			
			if (field == 'workflowConfig') {
				if (this.modelEditForm.input.workflowConfig != '') {
					this.modelEditForm.validate.workflowConfig = true
				} else {
					this.modelEditForm.validate.workflowConfig = false
				}
            }

            if (field == 'flow') {
                if (this.modelEditForm.input.selectedUserConfig.length != 0 && this.modelEditForm.input.selectedUserConfig[0] != null) {
                    this.modelEditForm.validate.flow = true
                } else {
                    this.modelEditForm.validate.flow = false
                }
            }
			
            if (
				this.modelEditForm.validate.workflowId &&
				this.modelEditForm.validate.workflowConfig &&
				this.modelEditForm.validate.flow
			)
				this.modelEditForm.submitBtn.disabled = false
		},
		updateItem () {
			this.$confirm({
				auth: false,
                message: "Ubah Alur Konfigurasi, lanjutkan proses?",
                button: {
					no: 'Tidak',
                    yes: 'Ya'
				},
				callback: confirm => {
					if (confirm) {
						let selectedUserConfig = this.modelEditForm.input.selectedUserConfig
						this.modelEditForm.input.positionId = this.formatFlow(selectedUserConfig)
						
						let positionDraft = (selectedUserConfig.length > 0)?selectedUserConfig[selectedUserConfig.length-1]:null
						this.modelEditForm.input.positionIdDraft = positionDraft.positionId

						this.modelEditForm.submitBtn = {
							loader: true,
							text: 'Memproses...'
						}

						this.$store.dispatch({
							type: 'workflow/update',
							formInput: this.modelEditForm.input
						}).then( res => {
							console.log("Form:", this.modelEditForm.input)
							this.getDataList()
								.then( data => {
									this.modelTable.dataList = data.items
									this.modelTable.totalData = data.total
									
									this.$toasted.success('Sukses mengubah data').goAway(3000)
									this.modelEditForm.submitBtn = {
										loader: false,
										text: 'Simpan'
									}
									setTimeout( e => {
										this.closeEditPopupModal()
									}, 500)
								})
						}).catch( err => {
							this.modelEditForm.submitBtn = {
								loader: false,
								text: 'Simpan'
							}
							let errMsg = 'Gagal menghapus data, silahkan coba kembali!'
							if (err.response.data) errMsg = err.response.data.error
							else if (err.message) errMsg = err.message
							this.$toasted.error(errMsg).goAway(3000)
						})
					}
				}
			})
		},
		
		// Remove Data
		deleteItem (item) {
			this.$confirm({
				auth: false,
                message: "Anda yakin ingin menghapus data ini?",
                button: {
					no: 'Tidak',
                    yes: 'Ya'
				},
				callback: confirm => {
					if (confirm) {
						this.$store.dispatch({
							type: 'workflow/destroy',
							formInput: item
						}).then( res => {
							console.log("Res: ", JSON.stringify(res.response))
							this.reloadDataList()
							this.$toasted.success('Sukses menghapus data').goAway(3000)
						}).catch( err => {
							let errMsg = 'Gagal menghapus data, silahkan coba kembali!'
							if (err.response.data) errMsg = err.response.data.error
							else if (err.message) errMsg = err.message
							this.$toasted.error(errMsg).goAway(3000)
						})
					}
				}
			})
        },

		reloadDataList() {
			this.loading = true
			this.modelTable.dataList = []
			this.modelTable.totalData = 0
			this.getDataList()
				.then( data => {
					this.modelTable.dataList = data.items
					this.modelTable.totalData = data.total
				}).finally (()=> {
					this.loading = false
				}
			)
		},

		makeItPublish(item) {
			this.$confirm({
				auth: false,
                message: "Anda yakin ingin Alur ini dapat digunakan oleh pengguna lain (Dalam Satuan Kerja)?",
                button: {
					no: 'Tidak',
                    yes: 'Ya'
				},
				callback: confirm => {
					if (confirm) {
						this.$store.dispatch({
							type: 'workflow/makeItPublish',
							formInput: item
						}).then( res => {
							if(res.error == 'workflow in used') {
								this.$toasted.success('Tidak dapat mengubah, karena Alur sedang digunakan')
							} else {
								this.$toasted.success('Sukses mengubah status data menjadi Publish').goAway(3000)
								this.reloadDataList()
							}
						}).catch( err => {
							let errMsg = 'Gagal mengubah status data, silahkan coba kembali!'
							this.$toasted.error(errMsg).goAway(3000)
						})
					}
				}
			})
		},
		makeItPrivate(item) {
			this.$confirm({
				auth: false,
                message: "Anda yakin ingin Alur ini hanya untuk anda?",
                button: {
					no: 'Tidak',
                    yes: 'Ya'
				},
				callback: confirm => {
					if (confirm) {
						this.$store.dispatch({
							type: 'workflow/makeItPrivate',
							formInput: item
						}).then( res => {
							if(res.error == 'workflow in used') {
								this.$toasted.success('Tidak dapat mengubah, karena Alur sedang digunakan')
							} else {
								this.$toasted.success('Sukses mengubah status data menjadi Private').goAway(3000)
								this.reloadDataList()
							}
						}).catch( err => {
							let errMsg = 'Gagal mengubah status data, silahkan coba kembali!'
							this.$toasted.error(errMsg).goAway(3000)
						})
					}
				}
			})
		},
	}
}
</script>