<template>
<div class="user-container">
	<CTabs :active-tab.sync="activeTab">
        <CTab>
            <template slot="title">Alur Persetujuan (Surat Keluar)</template>
            <workflow-table :is-disposition-prop="false"/>
        </CTab>
        <CTab>
            <template slot="title">Alur Verifikasi (Surat Masuk)</template>
            <workflow-table 
            :is-disposition-prop="true"
            :is-verification-flow-prop="true" />
        </CTab>
        <CTab>
            <template slot="title">Alur Persetujuan (Nota Dinas)</template>
            <workflow-table :is-disposition-prop="undefined" />
        </CTab>
	</CTabs>
</div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import WorkflowTable from '@/components/workflow/WorkflowTable.vue'
export default {
    names: 'Category',
    components: {
		'workflow-table': WorkflowTable
    },
	data () {
		return {
			activeTab: 0
		}
	}
}
</script>

<style scoped></style>